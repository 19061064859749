import React from 'react';
import './FAQPage.css'; // Ensure you have a CSS file to style this page
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import MapComponent from './MapComponent'; 
import FAQ from './FAQ';

const FAQPage = () => {
    const handleEnquireNow = () => {
        // Redirect to contact page or handle enquiry action
        window.location.href = '/contact'; // Adjust this path as needed
    };

    return (
        <div className="faq-page-container">
            {/* Banner Section */}
            <div className="faq-page-banner">
                <div className="faq-page-banner-overlay">
                    <h1>Frequently Asked Questions</h1>
                </div>
            </div>

            {/* FAQ Section */}
            <FAQ />
            
            {/* Our Mission Section */}
            <section className="faq-page-mission">
                <h2>Our Mission</h2>
                <p>
                    To be the most trusted and efficient gold-buying company by offering transparent transactions and top-notch customer service.
                </p>
                <button className="faq-page-enquire-button" onClick={handleEnquireNow}>Enquire Now</button>
            </section>


            {/* Map Section */}
            <section className="faq-page-map-section">
                <h2>
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="faq-page-map-icon" /> 
                    Our Location
                </h2>
                <MapComponent />
            </section>
        </div>
    );
};

export default FAQPage;
