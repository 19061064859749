import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Control menu visibility

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="navbar">
            <div className="container-fluid d-flex justify-content-between align-items-center">

                {/* Left Section - Logo */}
                <div className="left-section d-flex align-items-center">
                    <Link className="navbar-brand" to="/">
                        <img src="/images/akgoldlogo.png" alt="AK Gold Buyers" height="40" />
                    </Link>
                </div>

                {/* Hamburger Icon for Small Screens */}
                <div className="hamburger-menu" onClick={toggleMenu}>
                    <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
                </div>

                {/* Center Section - Menu */}
                <div className={`center-section ${isMenuOpen ? 'open' : ''}`}>
                    <ul className="navbar-nav d-flex flex-column flex-md-row">
                        <li className="nav-item">
                            <Link className="nav-link" to="/" onClick={toggleMenu}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about" onClick={toggleMenu}>About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/services" onClick={toggleMenu}>Services</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact" onClick={toggleMenu}>Contact Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/faq" onClick={toggleMenu}>FAQ</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/blog" onClick={toggleMenu}>Blog</Link>
                        </li>
                    </ul>
                </div>

                {/* Right Section - Follow Us and Call Us */}
                <div className="right-section d-none d-md-flex flex-column align-items-end">
                    <div className="follow-us text-center mb-2">
                        <span className="navbar-text me-2">FOLLOW US:</span>
                        <a href="https://www.facebook.com" className="ms-2"><i className="fab fa-facebook"></i></a>
                        <a href="https://www.linkedin.com" className="ms-2"><i className="fab fa-linkedin"></i></a>
                        <a href="https://twitter.com" className="ms-2"><i className="fab fa-twitter"></i></a>
                        <a href="https://www.youtube.com" className="ms-2"><i className="fab fa-youtube"></i></a>
                    </div>
                    <div className="navbar-text">
                        <a href="tel:+91 9581631666" className="call-us-button">
                            +91 9581631666 <i className="fas fa-headset"></i>
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
