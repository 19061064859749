import React from 'react';
import { Carousel } from 'react-bootstrap';
import './HeroSection.css';

const HeroSection = () => {
    return (
        <div>
            {/* Hero Section */}
            <div className="hero-section">
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/images/img2.jpg"
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3>మేము మీ బంగారం కొనుగోలు చేస్తాము</h3> {/* First Slide Title in Telugu */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/images/img1.jpg"
                            alt="Second slide"
                        />
                        <Carousel.Caption>
                            <h3>మీరు తాకట్టు పెట్టిన బంగారాన్ని విడిపించి కొనబడును</h3> {/* Second Slide Title in Telugu */}
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    );
};

export default HeroSection;
