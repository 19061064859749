import React from 'react';
import HeroSection from './HeroSection';
import AboutUs from './AboutUs';
import HowItWorks from './HowItWorks';
import WhyChoose from './WhyChoose';
import FAQ from './FAQ';
import MapComponent from './MapComponent';

const HomePage = () => {
    return (
        <div>
            <HeroSection />
            <AboutUs />
            <HowItWorks /> 
            <WhyChoose />
            <FAQ />
            <MapComponent />
            {/* Other sections of your homepage will go here */}
        </div>
    );
};

export default HomePage;
 