import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="footer-content">
                <div className="footer-column company-info">
                    <h3 className="footer-title">AK Gold Buyers</h3>
                    <p>Turning your gold into cash with trust and transparency.</p>
                    <p>&copy; 2024 AK Gold Buyers. All Rights Reserved.</p>
                </div>

                <div className="footer-column">
                    <h3 className="footer-title">Quick Links</h3>
                    <ul className="footer-links">
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                        <li><a href="/faq">FAQ</a></li>
                        <li><a href="/blog">Blog</a></li>

                    </ul>
                </div>

                <div className="footer-column">
                    <h3 className="footer-title">Contact Us</h3>
                    <p><FontAwesomeIcon icon={faEnvelope} /> goldbuyerak@gmail.com</p>
                    <p><FontAwesomeIcon icon={faPhone} /> +91 9581631666</p>
                    <p><FontAwesomeIcon icon={faMapMarkerAlt} />                         Plot No: 128, Kamalaprasanna Nagar, Allwyn Colony,
                    Opp: Iscon Temple, Kukatpally, Medchel Malkajgiri DIST Hyderabad - 500072</p>
                </div>

                <div className="footer-column social-media">
                    <h3 className="footer-title">Follow Us</h3>
                    <div className="social-icons">
                        <a href="#" className="social-icon">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a href="#" className="social-icon">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="#" className="social-icon">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
