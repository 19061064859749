import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import ScrollingText from './components/ScrollingText';
import HomePage from './components/HomePage'; // Assuming you have a HomePage component
import Footer from './components/Footer';
import AboutUsPage from './components/AboutUsPage';
import ContactUsPage from './components/ContactUsPage';
import ServicesPage from './components/ServicesPage';
import FloatingIconsWrapper from './components/FloatingIconsWrapper';
import FAQPage from './components/FAQPage'; // Importing the FAQPage component
import './components/FloatingIcons.css';
import BlogPage from './components/BlogPage'; // Importing the BlogPage component

function App() {
    return (
        <Router>
            <ScrollingText />
            <Navbar />
            <div style={{ minHeight: '80vh' }}> {/* Wrapper to ensure content pushes footer down */}
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about" element={<AboutUsPage />} />
                    <Route path="/services" element={<ServicesPage />} />
                    <Route path="/contact" element={<ContactUsPage />} />
                    <Route path="/faq" element={<FAQPage />} /> 
                    <Route path="/blog" element={<BlogPage />} /> {/* Added Blog Route */}
                </Routes>
            </div>
            <Footer />  {/* Footer will be rendered on all pages */}
            <FloatingIconsWrapper />
        </Router>
    );
}

export default App;
