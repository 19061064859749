import React from 'react';

// WhatsApp Floating Icon
const WhatsAppIcon = () => {
  return (
    <a href="https://wa.me/9581631666" target="_blank" rel="noopener noreferrer" className="whatsapp-icon">
      <img src="/images/whatsapp.svg" alt="WhatsApp" />
    </a>
  );
};

// Phone Floating Icon
const PhoneIcon = () => {
  return (
    <a href="tel:+919581631666" className="phone-icon">
      <img src="/images/phone.svg" alt="Phone" />
    </a>
  );
};

export { WhatsAppIcon, PhoneIcon };
