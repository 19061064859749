import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AboutUsPage.css';
import MapComponent from './MapComponent'; // Assuming you have the MapComponent for the location

const AboutUsPage = () => {
    const navigate = useNavigate(); // Initialize the navigation hook

    const handleEnquireClick = () => {
        navigate('/contact'); // Navigate to the Contact Us page
    };

    return (
        <div className="about-us-page">
            {/* Company Overview Section */}
            <section className="company-overview">
                <div className="company-overview-text">
                    <h1>Company Overview</h1>
                    <p>
                        At AK Gold Buyers, we are dedicated to providing a transparent and reliable gold-buying service in Hyderabad. Our core principle is to ensure every customer receives the best price for their gold, with a focus on honesty and professionalism.
                    </p>
                </div>
                <div className="company-overview-image">
                    <img src="/images/gold-buying.png" alt="Gold Buying" />
                </div>
            </section>

            {/* Mission Section */}
            <section className="mission-section">
                <div className="mission-text">
                    <h2>Our Mission</h2>
                    <p>
                        To be the most trusted and efficient gold-buying company by offering transparent transactions and top-notch customer service.
                    </p>
                </div>

                {/* Enquire Now Button under the Mission section */}
                <div className="enquire-now-container">
                    <button className="enquire-now-button" onClick={handleEnquireClick}>
                        Enquire Now ⟶
                    </button>
                </div>
            </section>

            {/* Why We’re Different Section */}
            <section className="why-different">
                <div className="why-different-image"> {/* Image swapped to the left */}
                    <img src="/images/different.png" alt="Why We’re Different" />
                </div>
                <div className="why-different-text"> {/* Text swapped to the right */}
                    <h2>Why We’re Different</h2>
                    <p>
                        Unlike other companies, we only buy gold from customers and do not sell it. This ensures our focus remains solely on providing the best buying price for your gold.
                    </p>
                </div>
            </section>

            {/* Map Section */}
            <section className="map-section">
                <h2>Our Location</h2>
                <MapComponent />
            </section>
        </div>
    );
};

export default AboutUsPage;
