import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AboutUs.css';

const AboutUs = () => {
    const navigate = useNavigate();

    const handleMoreAboutUs = () => {
        navigate('/about');
    };

    return (
        <div className="about-us-section">
            <h2 className="about-title">ABOUT US</h2>
            <div className="about-content">
                <div className="about-text">
                    <h3>SELL YOUR GOLD FOR INSTANT CASH AT <span>AK GOLD BUYERS</span></h3>
                    <p>Are you looking for the best gold buyers in Hyderabad? AK Gold Buyers is the best place to sell old gold at the highest price. Turn your Old Gold into cash. We also release your pledged gold from the bank and help you relax from paying high interest.</p>
                    <p>Our company is one of the safest & most trusted names in the precious metals space. We buy Gold at market value and promise to provide the right amount for your gold.</p>
                    <div className="more-button-container">
                        <button onClick={handleMoreAboutUs} className="about-us-button">More About Us ⟶</button>
                    </div>
                </div>
                <div className="center-image">
                    <img src="/images/aboutus.png" alt="About Us" />
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
