import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WhyChoose.css';

const WhyChoose = () => {
    const navigate = useNavigate();

    const handleEnquiryClick = () => {
        navigate('/contact');
    };

    return (
        <section className="why-choose-section">
            <div className="why-choose-title-container">
                <h2 className="why-choose-headline">Why Choose AK Gold Buyers</h2>
            </div>

            <div className="why-choose-content">
                <div className="why-choose-steps">
                    <div className="why-choose-row">
                        <div className="why-choose-image">
                            <img src="/images/gold-valuation.png" alt="Better Gold Valuation" />
                        </div>
                        <div className="why-choose-text">
                            <h3>Better Gold Valuation</h3>
                            <p>Receive a precise valuation for your gold using market-based appraisals.</p>
                        </div>
                    </div>

                    <div className="why-choose-row shifted">
                        <div className="why-choose-image">
                            <img src="/images/instant cash.png" alt="Instant Cash" />
                        </div>
                        <div className="why-choose-text">
                            <h3>Instant Cash</h3>
                            <p>Get paid in cash immediately after your gold is evaluated.</p>
                        </div>
                    </div>

                    <div className="why-choose-row">
                        <div className="why-choose-image">
                            <img src="/images/automated process.png" alt="Automated Process" />
                        </div>
                        <div className="why-choose-text">
                            <h3>Automated Process</h3>
                            <p>Experience a seamless, automated process for selling your gold.</p>
                        </div>
                    </div>

                    <div className="why-choose-row shifted">
                        <div className="why-choose-image">
                            <img src="/images/legacy.png" alt="Trusted Legacy" />
                        </div>
                        <div className="why-choose-text">
                            <h3>Trusted Legacy</h3>
                            <p>With a longstanding history, we are a trusted name in the industry.</p>
                        </div>
                    </div>
                </div>

                <div className="enquiry-button-container">
                    <button className="enquiry-button" onClick={handleEnquiryClick}>
                        Enquire Now ⟶
                    </button>
                </div>
            </div>
        </section>
    );
};

export default WhyChoose;
