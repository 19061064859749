import React, { useState } from 'react';
import './ContactUsPage.css';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import MapComponent from './MapComponent';

const ContactUsPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Send email using EmailJS
        emailjs.send(
            'service_vjflzhp', // Replace with your EmailJS Service ID
            'template_ij7pe4m', // Replace with your EmailJS Template ID
            {
                name: formData.name,
                phone: formData.phone,
                email: formData.email,
                message: formData.message,
            },
            'Wgl2KWf2ebQoxN0G-' // Replace with your EmailJS User ID
        )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert("Message sent successfully!");
        }, (error) => {
            console.error('FAILED...', error);
            alert("Failed to send message. Please try again later.");
        });

        // Clear form after submission
        setFormData({
            name: '',
            phone: '',
            email: '',
            message: ''
        });
    };

    return (
        <div className="contact-us-page">
            {/* Banner Section */}
            <div className="contact-banner">
                <div className="banner-overlay">
                    <h1>Get In Touch</h1>
                    <p>Reach out to us for any inquiries or support.</p>
                </div>
            </div>

            {/* Contact Information Section */}
            <div className="contact-info">
                <h2>Contact Details</h2>
            </div>

            {/* Contact Form Section */}
            <div className="contact-form-container">
                <div className="contact-text">
                    <h2>Send Us a Message</h2>
                    <p>
                        Fill out the form and we will get back to you within 24 hours. You can also reach us
                        via the provided phone or email for any urgent inquiries.
                    </p>

                    {/* Phone and Email Boxes */}
                    <div className="contact-info-grid">
                        <div className="info-item">
                            <FontAwesomeIcon icon={faPhone} className="info-icon" />
                            <h3>Phone</h3>
                            <p>+91 9581631666</p>
                        </div>
                        <div className="info-item">
                            <FontAwesomeIcon icon={faEnvelope} className="info-icon" />
                            <h3>Email</h3>
                            <p>goldbuyerak@gmail.com</p>
                        </div>
                    </div>

                    {/* Map Section */}
                    <section className="map-section">
                        <h2>
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="map-icon" /> 
                            Our Location
                        </h2>
                        <MapComponent />
                    </section>
                </div>

                <div className="contact-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" className="submit-button">Send Message</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactUsPage;
