import React, { useState, useEffect } from 'react';
import './ScrollingText.css';

const ScrollingText = () => {
    const messages = [
        "Welcome to AK Gold Buyers!",
        "Highest Prices Paid for Your Gold!",
        "Quick and Secure Transactions!",
        "Visit Us Today for a Free Quote!"
    ];

    const [isVisible, setIsVisible] = useState(true);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`scrolling-text-wrapper ${isVisible ? 'visible' : 'hidden'}`}>
            <div className="scrolling-text">
                {messages.map((message, index) => (
                    <div key={index} className="scrolling-text-item">
                        {message}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ScrollingText;
