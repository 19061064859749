import React, { useState, useEffect } from 'react';
import { WhatsAppIcon, PhoneIcon } from './FloatingIcons';
import ScrollToTop from './ScrollToTop';

const FloatingIconsWrapper = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleScroll = () => {
    const windowHeight = window.innerHeight + window.scrollY;
    const documentHeight = document.body.offsetHeight;

    // If the user is near the bottom of the page, hide the icons
    if (windowHeight >= documentHeight - 100) { // Adjust the offset if needed
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="floating-icons">
      {isVisible && (
        <>
          <WhatsAppIcon />
          <PhoneIcon />
        </>
      )}
      <ScrollToTop />
    </div>
  );
};

export default FloatingIconsWrapper;
